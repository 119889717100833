import PropTypes from "prop-types";
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from "react-router-dom";
import forexInformation from "../../data/forex/forexInformation"
import Tilt from 'react-parallax-tilt';

const ForexDetailContainer = () => {
    const [scale] = useState(0.95);
    let location = useLocation();
    let detailPageName = location.pathname.split("/")[2];
    const [myObj, setMyObj] = useState({
        id: 1,
        title: '',
        imageUrl: '',
        url: '',
        conditions: {
            1: '',
            2: '',
             }
    },)
    useEffect(() => {
        arrangeImage()
    }, [window.location.href])

    const arrangeImage = () => {
        forexInformation.forEach(element => {
            if (element.url === detailPageName) {
                setMyObj(element)
            }
        });
    }
    // 
    return (
        <>
            {myObj &&
                <div className="section section-padding whyhunfx-section ">
                    <div className="row">
                        <div className="col-lg-5 offset-lg-1">
                            <div className="custom-column-thumbnail mt-lg-14 mt-1" data-aos="fade-up">
                                <Tilt scale={scale} transitionSpeed={4000}>
                                    <img className="whyhunfx" src={`${process.env.PUBLIC_URL +'/'+ myObj.imageUrl}`} alt="" />
                                </Tilt>
                            </div>
                        </div>
                        <div className="col-lg-5">
                            <p className="col-lg-11 whyhunfx-p" data-aos="fade-up">
                                <ul className="promotion-details">
                                    <li>{myObj.conditions[1]}</li>
                                    <li>{myObj.conditions[2]}</li>
                                    {/* <li>{myObj.conditions[3]}</li>
                                    <li>{myObj.conditions[4]}</li>
                                    <li>{myObj.conditions[5]}</li>
                                    <li>{myObj.conditions[6]}</li> */}
                                </ul>
                                <br />
                                <div style={{display:"flex", justifyContent:"center"}}>
                                <Link className="btn btn-primary btn-hover-secondary mt-xl-8 mt-lg-8 mt-md-6 mt-4 " to={process.env.PUBLIC_URL + "/campaigns"}>Yatırım Kampanyaları</Link>

                                </div>

                            </p>
                        </div>
                    </div>
                </div>
            }</>

    )


}



ForexDetailContainer.propTypes = {
    data: PropTypes.object
};

export default ForexDetailContainer;

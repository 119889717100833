import React, { useRef } from 'react';
import PropTypes from "prop-types"
import {Link} from "react-router-dom";


const Btn = (props) => {
    var location = 'https://sca.' + (window.location.hostname.replace('www.', ''))
    // const openWebTrader = () => {
    //     webtrader.current.style.display = 'block'
    // }
    const webtrader = useRef()

    return (
        <div >
            {/* <div onMouseMove={openWebTrader}> */}
            <a href={location} style={{maxWidth: '4.5rem'}} className="btn btn-light btn-hover-primary btnLogIn">{props.name}</a>
            {/* </div> */}
        </div>
    )
}

Btn.propTypes = {
    name: PropTypes.string
}

export default Btn

import PropTypes from "prop-types";
import { useState } from "react";
import { Link } from "react-router-dom";
import Tilt from 'react-parallax-tilt';


const privacyAndSecurityPolicy = () => {
    const [scale] = useState(0.95);

    return (
        <div className="section section-padding whyhunfx-section ">
            {/* <div className="row"> */}
            {/* <div className="col-lg-5 offset-lg-1">
                    <div className="custom-column-thumbnail mt-lg-14 mt-1" data-aos="fade-up">
                        <Tilt scale={scale} transitionSpeed={4000}>
                            <img className="whyhunfx" src={"images/project/640x480-1.png"} alt="" />
                        </Tilt>
                    </div>
                </div> */}
            {/* <div className="col-lg-5"> */}
            <p className="col-lg-10 offset-lg-1 " data-aos="fade-up">
                HUN MARKETS (Şirket), geçerli yasal gerekliliklere uygun olarak yatırımcıdan elde edilen kişisel verilerin mahremiyetini ve gizliliğini korumayı taahhüt eder. Şirket, yalnızca yatırımcı ile iş ilişkisi için gerekli olduğu ölçüde veri toplayacaktır.
            </p>
            <p className="col-lg-10 offset-lg-1 " data-aos="fade-up">
                Bu Gizlilik ve Güvenlik Politikası, yatırımcının web sitelerimizi ziyaretleri sırasında toplanan veriler de dahil olmak üzere, Şirketin yatırımcı hakkında kişisel verileri nasıl topladığını, muhafaza ettiğini, kullandığını ve ilettiğini açıklar.
            </p>
            <h3 className="col-lg-10 offset-lg-1 " data-aos="fade-up">
                Yatırımcının Kişisel Verilerinin Toplanması ve Kullanımı
            </h3>
            <p className="col-lg-10 offset-lg-1 " data-aos="fade-up">
            Yatırımcının, iş ilişkisinin kurulması, hizmetlerin sağlanması ve alım satım hesaplarına ve faaliyetlerine erişim için gerekli kişisel verileri Şirkete sağlaması gerekmektedir. Kayıt işlemi sırasında sağlanan veriler, diğerlerinin yanı sıra; yatırımcının doğum tarihi, tam adı ve soyadı, tam ikamet ve/veya iş adresi, yatırımcının kimlik belgelerinde mevcut bilgiler (yani Pasaport / Kimlik Kartı), iletişim bilgileri (yani cep telefonu numarası, e-posta adresi). Bu bilgiler, Şirketin yatırımcıyı tanımlamasına ve doğrulamasına, yatırımcıya bir ticaret hesabı ve erişim kodu/şifresi etkinleştirmesine ve sağlamasına ve yatırımcı ile iletişime geçmesine ve mevcut hizmetler, ürünler ve promosyonlar hakkında bilgi sağlamasına olanak tanır.
            </p>
            <p className="col-lg-10 offset-lg-1 " data-aos="fade-up">
                Şirket ayrıca, Yatırımcının web sitelerini, özel arayüzleri veya iletişim kanallarını, ticaret yazılımlarını veya sağlanan platformları kullanımından da bilgi alır. Bu bilgiler, ziyaret edilen site alanları, görüntülenen sayfalar, ziyaretlerin sıklığı ve süresi, İnternet Protokolü (IP) adresi, bilgisayar tanımlama parametreleri, yürütülen işlem türleri, indirilen belgeler, fare tıklamaları, fare hareketleri ve kaydırma etkinliğini içerebilir.
            </p>
            <p className="col-lg-10 offset-lg-1 " data-aos="fade-up">
                Şirket, yatırımcılarının ihtiyaçlarını karşıladığından emin olmak için anonim istatistiksel bilgiler toplar. Şirket, bu tür verileri toplayarak mevcut ve potansiyel yatırımcılara sunulan hizmetleri izleyebilir ve iyileştirebilir. Şirket, bu tür bilgileri üçüncü kişilerle paylaşabilir.
            </p>
            <h3 className="col-lg-10 offset-lg-1 " data-aos="fade-up">
                Yatırımcı Bilgilerini Güncelleme
            </h3>
            <p className="col-lg-10 offset-lg-1 " data-aos="fade-up">
                Yatırımcının Şirket ile olan iş ilişkisi sırasında, Yatırımcıdan, yenilenen kimlik belgeleri, güncellenmiş iletişim bilgileri dahil olmak üzere, bu ilişkinin sürdürülmesi için gerekli olan kişisel verileri sağlaması istenebilir.
            </p>
            <p className="col-lg-10 offset-lg-1 " data-aos="fade-up">
                Ayrıca yatırımcı, kişisel bilgilerdeki herhangi bir değişikliği Şirkete bildirebilir. Şirket, yatırımcının kişisel bilgilerini yatırımcının talimatlarına uygun olarak değiştirecek ve Şirketin ilgili kişisel verileri yasal veya yasal amaçlarla tutması ve/veya yatırımcıya talep edilen hizmetleri sunması gerektiği durumlar dışında, önceden elde edilen bilgileri silecektir. veya iş kayıtlarını tutmak için.
            </p>
            <h3 className="col-lg-10 offset-lg-1 " data-aos="fade-up">
                Verilerin Üçüncü Kişilere Aktarılması
            </h3>
            <p className="col-lg-10 offset-lg-1 " data-aos="fade-up">
                Kişisel veriler, Şirket tarafından Avrupa Ekonomik Alanında ve Avrupa Ekonomik Alanı dışında bulunan üçüncü şahıslara yalnızca bu Politikada açıklanan amaçlar doğrultusunda aktarılabilir.
            </p>
            <p className="col-lg-10 offset-lg-1 " data-aos="fade-up">
                Üçüncü taraflar arasında Muhasebeciler, Denetçiler, Hukuk Müşavirleri, Bankalar ve diğer Kredi Kuruluşları, Ödeme Sistemleri ve Ödeme Hizmet Sağlayıcıları, Pazarlama ve Yatırımcı Destek Kuruluşları, Şirketin sözleşme ilişkisi içinde olduğu Hizmet Sağlayıcılar ve Şirket ile aynı Şirketler grubu içindeki kuruluşlar yer alabilir. Şirket.
            </p>
            <p className="col-lg-10 offset-lg-1 " data-aos="fade-up">
                Yatırımcının kimliğinin belirlenmesi ve doğrulanması amacıyla Şirket, yatırımcının verilerini, ilgili yasal gerekliliklere uygun olarak, bu tür bir tanımlamayı gerçekleştirmeye yetkili bir üçüncü taraf hizmet sağlayıcısına iletecektir. Şirket, iletilen verilerin mahremiyetini ve gizliliğini sağlayacaktır.
            </p>
            <p className="col-lg-10 offset-lg-1 " data-aos="fade-up">
                Şirket, herhangi bir düzenleyici kurum, kamu kurumu, mali istihbarat birimi, polis, devlet dairesi, vergi dairesi, kanun uygulayıcı kurumlar tarafından talimat verilmesi halinde veya kanunen gerekli olması halinde veya Şirket`in yatırımcının kişisel verilerini üçüncü şahıslara aktarma hakkını saklı tutar. mal ve/veya kişilere yönelik yakın fiziksel zararı veya zararı önlemek için bilgi paylaşımının gerekli olduğunu düşünmektedir.
            </p>
            <p className="col-lg-10 offset-lg-1 " data-aos="fade-up">
                Verilerin Şirket ile aynı Şirketler grubundaki kuruluşlara aktarımı, yalnızca yatırımcıya hizmetlerin sağlanması için sınırlı olacaktır.
            </p>
            <p className="col-lg-10 offset-lg-1 " data-aos="fade-up">
                Yatırımcı, bir iş ilişkisinin kurulmasına devam ederek, bu tür aktarımlara/aktarmalara açık ve net bir onay verir.
            </p>
            <h3 className="col-lg-10 offset-lg-1 " data-aos="fade-up">
                Çerezler
            </h3>
            <p className="col-lg-10 offset-lg-1 " data-aos="fade-up">
                Çerezler, yatırımcının Şirketin web sitesini/sitelerini ve/veya ticaret yazılımını kullanmasının bir sonucu olarak bilgisayarın sabit diskinde depolanan, genellikle benzersiz bir kimlik numarası veya değeri içeren küçük bilgi dosyalarıdır. Şirket, web sitesinin etkin çalışması ve bakımı için çerezleri kullanır.
            </p>
            <p className="col-lg-10 offset-lg-1 " data-aos="fade-up">
                Şirket, hem oturum kimliği tanımlama bilgilerini hem de kalıcı tanımlama bilgilerini kullanabilir. Oturum kimliği tanımlama bilgisi, İstemci tarayıcıyı kapattığında sona erer. Kalıcı bir çerez, yatırımcının sabit diskinde uzun bir süre kalır. Kalıcı çerezler, Şirketin web sitesi ziyaretçilerinin konumunu ve ilgi alanlarını izlemesini ve hedeflemesini ve Şirketin web sitesini/sitelerini ziyaret ederek deneyimlerini geliştirmesini sağlar. Teknolojik destek için Şirketin web sitesine/sitelerine erişimi olabilecek üçüncü şahıslar kendi çerezlerini kullanabilirler.
            </p>
            <h3 className="col-lg-10 offset-lg-1 " data-aos="fade-up">
                Güvenlik
            </h3>
            <p className="col-lg-10 offset-lg-1 " data-aos="fade-up">
            Şirket, yatırımcının sağladığı verilerin ve kişisel bilgilerin güvenliğinin, bütünlüğünün ve gizliliğinin kötüye kullanılmamasını sağlamak için uygun önlemleri sürdürmek için çaba göstermektedir. Bu tür sistemler ve prosedürler güvenlik ihlali riskini azaltırken, mutlak güvenlik sağlamazlar. Bu nedenle Şirket, hizmetlerin herhangi bir suiistimalden, arızadan, yasa dışı müdahalelerden veya burada saklanan bilgilere yetkisiz erişimden ve diğer bilgi güvenliği risklerinden veya yatırımcının Şirketin web sitesi/siteleri (dahil olmak üzere) üzerindeki veya bunlar aracılığıyla yaptığı özel iletişimlerden muaf olacağını garanti edemez. iletişim arayüzleri özel kalacaktır.
            </p>
            <p className="col-lg-10 offset-lg-1 " data-aos="fade-up">
            Yatırımcı, kişisel bilgilerdeki herhangi bir değişikliği Şirkete bildirebilir. Şirket, yatırımcının kişisel bilgilerini yatırımcının talimatlarına uygun olarak değiştirecek ve Şirketin ilgili kişisel verileri yasal veya yasal amaçlarla tutması ve/veya yatırımcıya talep edilen hizmetleri sunması gerektiği durumlar dışında, önceden elde edilen bilgileri silecektir. veya iş kayıtlarını tutmak için.
            </p>
            <h3 className="col-lg-10 offset-lg-1 " data-aos="fade-up">
                Linkler
            </h3>
            <p className="col-lg-10 offset-lg-1 " data-aos="fade-up">
            Yatırımcı, Şirketin web sitesini/sitelerini kullandığında, başka web sitelerine bağlantı verebilir. Bu Gizlilik ve Güvenlik Politikası, bu diğer siteler için geçerli değildir. Şirket, bu diğer siteler aracılığıyla üçüncü şahıslar tarafından toplanan hiçbir kişisel bilgiden sorumlu değildir.
            </p>
            <h3 className="col-lg-10 offset-lg-1 " data-aos="fade-up">
                Yürütme ve Değişiklikler
            </h3>
            <p className="col-lg-10 offset-lg-1 " data-aos="fade-up">
            Yatırımcı, kayıt işlemine devam ederek, kişisel verilerin toplanması, saklanması ve kullanılması ve üçüncü taraflara iletilmesi dahil olmak üzere bu Politika`da belirtilen tüm hükümleri kabul ve muvafakat eder.
            </p>
            <p  className="col-lg-10 offset-lg-1 " data-aos="fade-up">
            Şirket, bu Politikanın şartlarını zaman zaman değiştirebilir ve güncelleyebilir. En güncel versiyon Şirketin internet sitesinde yer alacaktır. Yatırımcının, kişisel verilerle ilgili güncel bilgilerden haberdar olması için web sitesini sık sık ziyaret etmesi tavsiye edilir.
            </p>
            <p className="col-lg-10 offset-lg-1 " data-aos="fade-up">
            Yatırımcının bu Gizlilik ve Güvenlik Politikası ve bunların uygulanmasıyla ilgili herhangi bir sorusu veya endişesi varsa, bunları şu adrese yönlendirebilir: support@hunmarkets.com
            </p>
            {/* </div> */}
            {/* </div> */}
        </div>

    )
}

privacyAndSecurityPolicy.propTypes = {
    data: PropTypes.object
};

export default privacyAndSecurityPolicy;

import PropTypes from "prop-types";
import {useState} from "react";
import { Link } from "react-router-dom";
import Tilt from 'react-parallax-tilt';


const SecurityAbout = () => {
    const [scale] = useState(0.95);

    //test commit

    return (
        <div className="section section-padding whyhunfx-section ">
            <div className="row">
                <div className="col-lg-5 offset-lg-1">
                    <div className="custom-column-thumbnail mt-lg-14 mt-1" data-aos="fade-up">
                        <Tilt scale={scale} transitionSpeed={4000}>
                            <img className="whyhunfx" src={"images/project/630x675-Guvenlik.png"} alt="" />
                        </Tilt>
                    </div>
                </div>
                <div className="col-lg-5">
                    <p className="col-lg-10 mx-5 whyhunfx-p" data-aos="fade-up">
                    Hun Markets denetimli bir broker olarak Genel Merkezi Asya pazarında Japonya{`'`} da dır. Hong Kong, Singapur, Vanuatu Cumhuriyeti ve British Virgin Adaları’nda ofisleri olup finansal regülatorlere bağlıdır. Dolayısıyla, yatırımcı fonlarının idaresi, güvenlik ve finansal raporlama konularında katı uyumluluk standartlarına tabi olarak faaliyet göstermekteyiz.<br/><br/>
                       <p>Lisanslarımız ve regülasyonlarımız konusunda daha fazla bilgiye website üzerinden ulaşabilirsiniz. Şeffaflığın güçlü bir savunucusu olarak, yatırımcılarımızın fonları için en yüksek güvenlik standartlarını oluşturuyoruz. Bu nedenle, yatırımcı fonları büyük uluslararası bankalarda tutulur ve şirketin kendi fonlarından tamamen ayrılır.</p>
                       <div style={{display:"flex", justifyContent:"center"}}>
                                <Link className="btn btn-primary btn-hover-secondary mt-xl-8 mt-lg-8 mt-md-6 mt-4 " to={process.env.PUBLIC_URL + "/campaigns"}>Yatırım Kampanyaları</Link>

                                </div>
                    </p>
                </div>
            </div>

            {/* <div className="row mt-lg-20 mt-12">
                <div className="col-lg-4 col-md-12 col-12">
                    <div className="digital-marketing" data-aos="fade-up">
                        <h3 className="heading heading-h3">{data.subContent.title}</h3>
                    </div>
                </div>
                <div className="col-lg-7 col-md-12 col-12 offset-lg-1">
                    <div className="digital-marketing mt-lg-0 mt-6" data-aos="fade-up">
                        <div className="inner">
                            <p>{data.subContent.text}</p>
                        </div>
                    </div>
                </div>
            </div> */}

            {/* <div className="custom-layout-gallery mt-lg-20 mt-12">
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-12">
                        <div className="thumbnail" data-aos="fade-up">
                            <img className="w-100" src={`${process.env.PUBLIC_URL}/${data.gallery.imageOne}`} alt="Agency" />
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-12 mt-lg-0 mt-md-0 mt-10">
                        <div className="thumbnail" data-aos="fade-up">
                            <img className="w-100" src={`${process.env.PUBLIC_URL}/${data.gallery.imageTwo}`} alt="Agency" />
                        </div>
                    </div>

                    <div className="col-lg-12 my-6">
                        <div className="thumbnail" data-aos="fade-up">
                            <img className="w-100" src={`${process.env.PUBLIC_URL}/${data.gallery.imageThree}`} alt="Agency" />
                        </div>
                    </div>
                </div>
            </div> */}


        </div>

    )
}

SecurityAbout.propTypes = {
    data: PropTypes.object
};

export default SecurityAbout;

import PropTypes from "prop-types";
import React from 'react';
import {Link} from "react-router-dom";

const WorkItemTwo = ({ data }) => {
    return (
        <div className="work">
            <div className="thumbnail">
                <Link  className="image" to={process.env.PUBLIC_URL + `/campaigns/${data.url}`}><img src={`${process.env.PUBLIC_URL +'/'+ data.imageUrl}`} alt="work" /></Link>
            </div>
            <div className="info campaigns">
                <h3 className="title"><Link to={process.env.PUBLIC_URL + `/campaigns/${data.url}`}>{data.title}</Link></h3>
                <p className="desc">{data.excerpt}</p>

            </div>
        </div>
    )
}

WorkItemTwo.propTypes = {
    data: PropTypes.object
};

export default WorkItemTwo;

import React from 'react';
import { NavLink } from 'react-router-dom';
import {
    getClosest,
    getSiblings,
    slideToggle,
    slideUp,
} from "../../../utils";

const MobileNavMenu = () => {
    const onClickHandler = (e) => {
        const target = e.currentTarget;
        const parentEl = target.parentElement;
        if (
            parentEl?.classList.contains("menu-toggle") ||
            target.classList.contains("menu-toggle")
        ) {
            const element = target.classList.contains("icon")
                ? parentEl
                : target;
            const parent = getClosest(element, "li");
            const childNodes = parent.childNodes;
            const parentSiblings = getSiblings(parent);
            parentSiblings.forEach((sibling) => {
                const sibChildNodes = sibling.childNodes;
                sibChildNodes.forEach((child) => {
                    if (child.nodeName === "UL") {
                        slideUp(child, 1000);
                    }
                });
            });
            childNodes.forEach((child) => {
                if (child.nodeName === "UL") {
                    slideToggle(child, 1000);
                }
            });
        }
    };
    var location = 'https://sca.' + (window.location.hostname.replace('www.', ''))
    var wt = 'https://wt.' + (window.location.hostname.replace('www.', ''))

    return (
        <nav className="site-mobile-menu">
            <ul>
                <li className="has-children">
                    <NavLink to={process.env.PUBLIC_URL + "/about"}><span className="menu-text">Hakkımızda</span></NavLink>
                    <span className="menu-toggle" onClick={onClickHandler}><i className="icon fa fa-angle-down"></i></span>
                    <ul className="sub-menu">
                        <li><NavLink to={process.env.PUBLIC_URL + "/whyhunyatirim"}><span className="menu-text">Neden Hun Markets</span></NavLink></li>
                        <li><NavLink to={process.env.PUBLIC_URL + "/security"}><span className="menu-text">Güvenlik</span></NavLink></li>
                        {/* <li><NavLink to={process.env.PUBLIC_URL + "/home-three"}><span className="menu-text">Home Three</span></NavLink></li> */}
                    </ul>
                </li>
                <li className="has-children"> 
                    <NavLink to={process.env.PUBLIC_URL + "/globalyatirim"}><span className="menu-text">Yatırım Araçları</span></NavLink>

                    <span className="menu-toggle" onClick={onClickHandler}><i className="icon fa fa-angle-down"></i></span> 
                     <ul className="sub-menu"> 
                        <li><NavLink to={process.env.PUBLIC_URL + "/globalyatirim"}><span className="menu-text">Döviz Alım-Satım</span></NavLink></li>
                        <li><NavLink to={process.env.PUBLIC_URL + "/globalyatirim/globalyatirim-education"}><span className="menu-text">Kripto Yatırım İşlemleri</span></NavLink></li> 
                         <li><NavLink to={process.env.PUBLIC_URL + "/globalyatirim/globalyatirim-analysis"}><span className="menu-text">Hisse Senedi Yatırımları</span></NavLink></li> 
                         <li><NavLink to={process.env.PUBLIC_URL + "/globalyatirim/emtias"}><span className="menu-text">Emtia Yatırım İşlemleri</span></NavLink></li> 
                         <li><NavLink to={process.env.PUBLIC_URL + "/globalyatirim/parites"}><span className="menu-text">Endeks Yatırım İşlemleri</span></NavLink></li>
                     </ul>
                 </li>
                <li className="has-children">
                    <NavLink to={process.env.PUBLIC_URL + "/campaigns"}><span className="menu-text">Promosyonlar</span></NavLink>

                    <span className="menu-toggle" onClick={onClickHandler}><i className="icon fa fa-angle-down"></i></span>
                    <ul className="sub-menu">
                        <li><NavLink to={process.env.PUBLIC_URL + "/campaigns/yuzde-30-bonus"}><span className="menu-text">%30 Hoşgeldin Bonusu</span></NavLink></li>
                        <li><NavLink to={process.env.PUBLIC_URL + `/campaigns/25-reference-bonus`}><span className="menu-text">%25 Referans Bonusu</span></NavLink></li>
                        <li><NavLink to={process.env.PUBLIC_URL + `/campaigns/cryptocurrency-deposit-bonus`}><span className="menu-text">%10 Kripto Yatırım Bonusu</span></NavLink></li>
                        <li><NavLink to={process.env.PUBLIC_URL + `/campaigns/lot-refund`}><span className="menu-text">Lot İade Kampanyası</span></NavLink></li>
                        <li><NavLink to={process.env.PUBLIC_URL + `/campaigns/iphone-14`}><span className="menu-text">iPhone 14 Pro Max Kampanyası</span></NavLink></li>

                    </ul>
                </li>
                <li>
                    <NavLink to={process.env.PUBLIC_URL + "/trading-platforms"}><span className="menu-text">İşlem Platformları</span></NavLink>

                </li>
                <li>
                    <NavLink to={process.env.PUBLIC_URL + "/contact"}><span className="menu-text">İletişim</span></NavLink>

                </li>
                <li>
                    <a href={location}><span style={{color:"white"}}>Giriş Yap</span></a>
                    <a href={wt}><span style={{color:"primary"}}>WebTrader</span></a>

                </li>
            </ul>
        </nav>
    )
}

export default MobileNavMenu;

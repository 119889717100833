import React from 'react';
import { Link } from "react-router-dom";
import Logo from '../../components/logo/Logo';
import FooterData from '../../data/Footer/footerItem.json';
import FooterLinkItem from '../../components/Footer/FooterLinkItem.jsx';
import Popup from 'reactjs-popup';

const Footer = () => {
    return (
        <div className="footer-section section footer-bg-color">
            <div className="container">
                <div className="row mb-lg-14 mb-md-10 mb-6">

                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12 col-12 mb-6">
                        <div className="footer-widget">
                            <div className="footer-logo">
                               
                                 <a href='/'> <img className='footer-logo' src={`${process.env.PUBLIC_URL}/images/logo/hunfx-logo-stickyheader2.png`}/> </a> 
                                
                            </div>
                            <div className="footer-widget-content">
                                <div className="content">


                                    <p><Link to={process.env.PUBLIC_URL + "/"}>+44 7311 884 329</Link></p>
                                    <p><Link to={process.env.PUBLIC_URL + "/"}>support@hunmarkets.com</Link> </p>
                                </div>
                                <div className="footer-social-inline">
                                    <a href="https://twitter.com/HunFinancial?t=RtAlYaVoxWp2hcDOjczpIA&s=09"><i className="fab fa-twitter-square"></i></a>
                                    {/* <a href="#"><i className="fab fa-facebook-square"></i></a> */}
                                    <a href="https://www.instagram.com/hunfinance/?igshid=YmMyMTA2M2Y%3D"><i className="fab fa-instagram"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>

                    {FooterData && FooterData.map((single, key) => {
                        return (
                            <div key={key} className="col-xl-2 col-lg-2 col-md-4 col-sm-6 col-6 mb-6">
                                <FooterLinkItem data={single} key={key} />

                            </div>

                        );
                    })}
                    <div style={{ listStyle: "none",marginTop:"40px" }} className="col-xl-2 col-lg-2 col-md-4 col-sm-6 col-6 mb-6">
                        <li><a download="risk_bildirim.pdf" href='static/risk_bildirim.pdf'>Kaldıraçlı İşlemler Risk Bildirim Formu</a></li>
                        {/* :
                        <li><a download="leverage-trading-agreement.pdf" href='static/leverage-trading-agreement.pdf'>Kaldıraçlı İşlemler Risk Bildirim Formu</a></li> */}
                        <Popup
                        trigger={<li style={{marginTop:"5px"}}><a>Hun Markets Lisansını Görüntüle</a></li>}
                        modal
                        nested
                    >
                        {close => (
                            <div className="modal-license">
                                <button className="close" onClick={close}>
                                    &times;
                                </button>
                                <div className="header">Hun Markets ASIC Sertifikası</div>
                                <div className="content text-center ">
                                    <img style={{ maxHeight: "79vh"}} src="images/license/asic.jpg" />
                                </div>
                            </div>
                        )}
                    </Popup>

                    </div>
                    

                </div>

                <div className="row">
                    <div className="col">
                        <p className="copyright">&copy; {new Date().getFullYear()} <strong>HUN MARKETS. </strong><strong>Tüm Hakları Saklıdır.</strong></p>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Footer

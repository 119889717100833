import PropTypes from "prop-types";
import {useState} from "react";
import { Link } from "react-router-dom";
import Tilt from 'react-parallax-tilt';


const ForexMain = () => {
    const [scale] = useState(0.95);

    return (
        <div className="section section-padding whyhunfx-section  ">
            <div className="row">
                <div className="col-lg-5 offset-lg-1">
                    <div className="custom-column-thumbnail mt-lg-14 mt-1" data-aos="fade-up">
                        <Tilt scale={scale} transitionSpeed={4000}>
                            <img className="whyhunfx" src={"images/project/640x480-1.png"} alt="" />
                        </Tilt>
                    </div>
                </div>
                <div className="col-lg-5">
                    <p className="col-lg-10 mx-5  whyhunfx-p" data-aos="fade-up">
                        <h5>Değişen Dünya Ekonomisindeki Rolü</h5>
                        Döviz alım-satımı, küresel finans piyasalarında dikkate değer bir yer tutan ve yatırımcılara farklı para birimleri arasındaki değer değişimlerinden yararlanma fırsatı sunan bir finansal piyasadır. Bu yazıda, Forex piyasasının dinamiklerini ve döviz alım-satımının önemini ele alacağız
                       <h5 style={{paddingTop:"15px"}}>Forex Piyasasının Önemi</h5>
                       <p>Forex piyasası, dünya ekonomisinin temel bir bileşenidir ve birçok finansal işlemi kolaylaştırır. İşletmeler, hükümetler ve yatırımcılar, döviz alım-satımı yoluyla uluslararası ticaret ve yatırım yapabilirler. Bu piyasa aynı zamanda döviz kurlarındaki dalgalanmaları izleyerek ekonomik gelişmeleri yansıtır.</p>
                       <h5 style={{paddingTop:"15px"}}>Döviz Alım-Satımının Temeli</h5>
                       <p>Döviz alım-satımının temeli, bir para biriminin diğerine karşı değer kazanacağı veya kaybedeceği beklentisine dayanır. Bu beklentilere göre yatırımcılar, bir para birimini alır veya satarlar. Örneğin, USD/JPY paritesinde Amerikan Doları`nın Japon Yeni karşısında değer kazanacağını tahmin eden bir yatırımcı, USD alır. Daha sonra, Amerikan Doları değer kazandığında Japon Yeni`ye çevirir ve kar elde eder.</p>

                       <h5 style={{paddingTop:"15px"}}>Risk Yönetimi</h5>
                       <p>Forex piyasası, yüksek kaldıraç kullanımı ve anlık fiyat dalgalanmaları nedeniyle yatırımcılar için risk içerir. Bu nedenle, risk yönetimi önemlidir. Stop-loss emirleri ve limit emirleri gibi araçlar kullanarak yatırımcılar, olası zararları sınırlayabilirler. Ayrıca, piyasa analizleri ve güncel haberleri takip ederek daha bilinçli işlem kararları alabilirler.</p>

                       <h5 style={{paddingTop:"15px"}}>Temel ve Teknik Analiz</h5>
                       <p>Döviz alım-satımı yaparken, yatırımcılar temel analiz ve teknik analiz gibi iki ana analiz türünü kullanırlar. Temel analiz, ekonomik verileri, ülke politikalarını ve küresel olayları inceleyerek para birimi değerlerinin nasıl etkilenebileceğini tahmin etmeye çalışır. Teknik analiz ise grafikler, göstergeler ve geçmiş fiyat hareketleri gibi verilere dayanarak gelecekteki fiyat hareketlerini tahmin etmeye çalışır.</p>

                       <h5 style={{paddingTop:"15px"}}>İleri Düzey İşlemler</h5>
                       <p>Döviz alım-satımı, yatırımcılara sadece temel para birimi çiftleri üzerinde işlem yapma fırsatı sunmaz. Aynı zamanda daha karmaşık ticaret stratejileri, egzotik para birimleri ve türev ürünler gibi ileri düzey işlemler de sunar. Ancak bu tür işlemler daha fazla deneyim ve uzmanlık gerektirebilir.</p>
                       <div style={{display:"flex", justifyContent:"center"}}>
                                <Link className="btn btn-primary btn-hover-secondary mt-xl-8 mt-lg-8 mt-md-6 mt-4 " to={process.env.PUBLIC_URL + "/campaigns"}>Kampanyalar</Link>

                                </div>
                    </p>
                </div>
            </div>

            {/* <div className="row mt-lg-20 mt-12">
                <div className="col-lg-4 col-md-12 col-12">
                    <div className="digital-marketing" data-aos="fade-up">
                        <h3 className="heading heading-h3">{data.subContent.title}</h3>
                    </div>
                </div>
                <div className="col-lg-7 col-md-12 col-12 offset-lg-1">
                    <div className="digital-marketing mt-lg-0 mt-6" data-aos="fade-up">
                        <div className="inner">
                            <p>{data.subContent.text}</p>
                        </div>
                    </div>
                </div>
            </div> */}

            {/* <div className="custom-layout-gallery mt-lg-20 mt-12">
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-12">
                        <div className="thumbnail" data-aos="fade-up">
                            <img className="w-100" src={`${process.env.PUBLIC_URL}/${data.gallery.imageOne}`} alt="Agency" />
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-12 mt-lg-0 mt-md-0 mt-10">
                        <div className="thumbnail" data-aos="fade-up">
                            <img className="w-100" src={`${process.env.PUBLIC_URL}/${data.gallery.imageTwo}`} alt="Agency" />
                        </div>
                    </div>

                    <div className="col-lg-12 my-6">
                        <div className="thumbnail" data-aos="fade-up">
                            <img className="w-100" src={`${process.env.PUBLIC_URL}/${data.gallery.imageThree}`} alt="Agency" />
                        </div>
                    </div>
                </div>
            </div> */}


        </div>

    )
}

ForexMain.propTypes = {
    data: PropTypes.object
};

export default ForexMain;

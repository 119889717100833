import React from 'react';
import { useLocation, useParams } from "react-router-dom";
import SEO from '../components/SEO';
import Header from "../partials/header/Header";
import Breadcrumb from '../container/Breadcrumb/Breadcrumb';
import Footer from '../container/Footer/Footer';
// import WorkData from "../data/work/workDetails";
import WorkDetailsContainer from '../container/Work/WorkDetailsContainer';
import ScrollToTop from '../components/ScrollToTop.jsx';
import forexInformation from "../data/forex/forexInformation"
import ForexDetailContainer from '../container/Forex/ForexDetailContainer';



const ForexDetails = () => {
    let location = useLocation();
    let detailPageName = location.pathname.split("/")[2];
    let myObj;
    forexInformation.forEach(element => {

        if (element.url === detailPageName) {
            myObj = element
        }
    });
    // console.log(myObj.title);
    return (
        <React.Fragment>
            <SEO title="Hun Markets - Global Yatırım" />
            <Header />
            <Breadcrumb 
                image="images/bg/1920x662-Forex.png"
                title={myObj.title}
                content="Anasayfa"
                contentTwo={myObj.title}
            />
            <ForexDetailContainer  />
            <Footer />
            <ScrollToTop />
        </React.Fragment>
    )
}

export default ForexDetails;
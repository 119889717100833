import React from 'react';
import { useParams } from "react-router-dom";
import SEO from '../components/SEO';
import Header from "../partials/header/Header";
import Breadcrumb from '../container/Breadcrumb/Breadcrumb';
import Footer from '../container/Footer/Footer';
import WorkData from "../data/work/workDetails";
import WorkDetailsContainer from '../container/Work/WorkDetailsContainer';
import ScrollToTop from '../components/ScrollToTop.jsx';
import WhyHunFX from '../container/About/WhyHunFX';
import ContactInformationThree from '../container/ContactInformation/ContactInformationThree';



const WhyHunFx = () => {
    // let {id} = useParams();
    // const workId = parseInt(id, 10)
    // const data = WorkData.filter(work => work.id === workId);
    return (
        <React.Fragment>
            <SEO title="Hun Markets - Neden Hun Markets" />
            <Header />
            <Breadcrumb 
                image="images/bg/1920x540-Hakkimizda.png"
                title="Neden Hun Markets"
                content="Anasayfa"
                contentTwo="Neden Hun Markets"
            />
            <WhyHunFX />
            <ContactInformationThree/>
            <Footer />
            <ScrollToTop />
        </React.Fragment>
    )
}

export default WhyHunFx;
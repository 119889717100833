const Campaigns = [
    {
        id: 1,
        title: '%30 Hoşgeldin Bonusu',
        imageUrl: "images/campaigns/630x675-Promosyonlar-bonus-30-promosyon.png",
        url: "yuzde-30-bonus",
        conditions: {
            1: "Hun Markets'de gerçek hesap açtıktan sonra ilk yatırımınızda geçerlidir.",
            2: "Kazanılan bonus miktarı yapılacak yatırım işlemlerinde marjin seviyesini yükseltmek için takviye görevi görür, çekilemez. Fakat bonus kullanılarak elde edilen kâr çekilebilir. Bonus alındıktan sonra stop out olunması durumunda, yatırımcının stop out olduktan sonra hesabında kalan meblağ almış olduğu bonus miktarı veya daha azı kadar ise, hesabında kalan meblağ almış olduğu bonusa eşdeğer sayılacağı için bu miktar için çekim talebinde bulunulamaz. Örneğin; 1.000 $ yatırıp %30 Yatırım Bonusu ile 300$ hoşgeldin bonusu almış olan bir yatırımcı stop out olması durumunda, hesabında 300 $ veya daha düşük bir miktar kaldığında, bu meblağ yatırımcının hesabından düşülür.",
            3: "Promosyona katılım için web sitemizden Hun Markets Canlı Destek Hattı veya Whatsapp üzerinden yatırımcı temsilcileri ile iletişime geçilmesi yeterlidir.",
            4: "Hun Markets, bu promosyonun kurallarını değiştirme veya promosyonu tamamen iptal etme hakkına sahiptir. Herhangi bir promosyonun güncel kurallarını takip etme yükümlülüğü yatırımcının kendisine aittir.",
            5: "Maksimum alınabilir bonus miktarı 5000$’dır.",
            6: "Bu kampanya, başka kampanya ile birleştirilemez. Hun Markets al-sat kurallarının ihlal edilmesi durumunda, bildirim yapmadan kampanya koşullarında değişiklik ve iptal hakkını saklı tutar.",
        }
    },
    {
        id: 2,
        title: '%25 Referans Bonusu',
        imageUrl: "images/campaigns/630x675-Promosyonlar-referans.png",
        url: "25-reference-bonus",
        conditions: {
            1: "Gerçek hesap sahibi olan yatırımcılarımız şirketimize yeni yatırımcılar için referans olması durumunda ilk yatırımları üzerinden %10 bonus kampanyasına hak kazanabilir.",
            2: "Tüm yatırımcılarımızın yeni referanslarının sadece ilk yatırımlarında geçerli olan bonus kampanyasıdır.",
            3: "Bonus ile hesaba eklenen tutar çekilebilir bir tutar değildir. Referans bonusu, başka herhangi bir kampanya ile birleştirilemez.",
            4: "Yatırımcılarımız elde ettikleri karlarını çekerken bonus tutarları silinmez.",
            5: "Bonus hak edişi elde edilirken yatırılmış olan fon miktarı çekilmek istenirse öncesinde bonus tutarının silinmesi gerekmektedir.",
            6: "Hun Markets al-sat kurallarının ihlal edilmesi durumunda, bildirim yapmadan kampanya koşullarında değişiklik ve iptal hakkını saklı tutar. Kampanyada yapılacak herhangi bir değişikliği takip etme sorumluluğu yatırımcılarımızın kendilerine aittir. Bu bonustan hak edilebilecek max. bonus tutarı 1000$’dır."
        }
    },
    {
        id: 3,
        title: '%10 Kripto Yatırım Bonusu',
        imageUrl: "images/campaigns/630x675-Promosyonlar-kripto.png",
        url: "cryptocurrency-deposit-bonus",
        conditions: {
            1: "Çekilebilir bonus Tether (USDT).",
            2: "Bu promosyondan ilk kez yatırımda bulunacak olan üyeler faydalanabilir.",
            3: "Bu promosyondan faydalanabilmesi için yatırımcının hesabına fonlamayı kabul edilen kripto paralar ile gerçekleştirmesi gerekmektedir.",
            4: "Kazanılan bonus miktarını hak edebilmek için %2 lot çevrim yapılması ve 1 ay çekim yapılmaması gerekmektedir.",
            5: "Promosyona katılmak için canlı destek hattı veya tarafınıza özel atanan yatırımcı temsilciniz ile iletişime geçmeniz yeterlidir.",
            6: "Hun Markets al-sat kurallarının ihlal edilmesi durumunda, bildirim yapmadan kampanya koşullarında değişiklik ve iptal hakkını saklı tutar. Herhangi bir promosyonun güncel kurallarını takip etme yükümlülüğü yatırımcının kendisine aittir."
        }
    },
    {
        id: 4,
        title: 'Lot İade Kampanyası',
        imageUrl: "images/campaigns/630x675-Promosyonlar-lot.png",
        url: "lot-refund",
        conditions: {
            1: "Hun Markets Lot İadesi Kampanyası diğer kampanyalarla aynı anda kullanılamaz.",
            2: "Bu kampanyadan sadece Hun Markets ile doğrudan, herhangi bir aracı olmadan çalışan yatırımcılar yararlanabilir.",
            3: "Yatırımcı dilediği zaman kampanyayı sonlandırabilir ve o ay hakkı olan iadeyi alır, ama sonrasında firmamızla çalıştığı dönem boyunca sonraki aylarda herhangi bir iade hakkı kalmamış olur.",
            4: "Bu kampanyamızdan hak kazanmak için yatırımcının o ay içerisinde minimum 50 lot işlem yapması gerekmektedir. (Kurumumuz düşük spread uygulamaktadır.) Lot tutarı hesaplanırken yatırımcının altın, Usoil ve Brent işlemleri göz önüne alınacaktır.",
            5: "Yatırımcımızın alacağı iade o ayki yatırımı ile sınırlı olacaktır.",
            6: "Hun Markets al-sat kurallarının ihlal edilmesi durumunda, bildirim yapmadan kampanya koşullarında değişiklik ve iptal hakkını saklı tutar"
        }
    },
    {
        id: 5,
        title: 'iPhone 14 Pro Max Kampanyası!',
        imageUrl: "images/campaigns/630x675-Promosyonlar-iphone14.png",
        url: "iphone-14",
        conditions: {
            1: "Kampanya 31 Aralık 2023 tarihine kadar geçerlidir.",
            2: "Yatırımcının yatırım tutarının en az 1000 USD olması gerekmektedir.",
            3: "Yatırımcı 3 ay sonunda kapanmış 500 Lot işlem karşılığı iPhone 14 PRO MAX’dan yararlanabilecektir. Yatırımcı verilen süre içinde lot şartını tamamlayamazsa iPhone PRO hakkı sonlandırılacaktır.",
            4: "Yatırımcı kampanyadan yararlanmadan önce yatırımcı temsilcisiyle iletişime geçer.",
            5: "Her yatırımcı bu kampanyadan bir kere yararlanabilir ve kampanya diğerleriyle birleştirilemez. Hun Markets al-sat kurallarının ihlal edilmesi durumunda, bildirim yapmadan kampanya koşullarında değişiklik ve iptal hakkını saklı tutar",
            6: "Lot tutarı hesaplanırken yatırımcının FX, altın, Usoil ve Brent işlemleri göz önüne alınacaktır."
        }
    }
]

export default Campaigns;
import React from 'react';
import SEO from '../components/SEO';
import Header from "../partials/header/Header";
import Breadcrumb from '../container/Breadcrumb/Breadcrumb';
import Footer from '../container/Footer/Footer';
import ScrollToTop from '../components/ScrollToTop.jsx';
import ForexMain from '../container/Forex/ForexMain';
import ContactFromContainer from '../container/ContactFromContainer/ContactFromContainer';
import ContactInformationThree from '../container/ContactInformation/ContactInformationThree';
import PrivacyAndSecurityPolicy from '../container/PrivacyandSecurity/PrivacyAndSecurityPolicy';
const PrivacyansSecurityPolicy = () => {
    return (
        <React.Fragment>
            <SEO title="Hun Markets - Gizlilik ve Güvenlik Politikası" />
            <Header />
            <Breadcrumb 
                image="images/bg/1920x540-Iletisim.png"
                title="Gizlilik Ve Güvenlik Politikası?"
                content="Anasayfa"
                contentTwo="Gizlilik Ve Güvenlik Politikası?"
            />
            <PrivacyAndSecurityPolicy />
            <ContactInformationThree />
            {/* <AboutFive /> */}
            {/* <BrandContainer classOption="section-padding-bottom" /> */}
            {/* <Faq /> */}
            <Footer />
            <ScrollToTop />
        </React.Fragment>
    )
}

export default PrivacyansSecurityPolicy;
import React from 'react';
import SEO from '../components/SEO';
import Header from "../partials/header/Header";
import Breadcrumb from '../container/Breadcrumb/Breadcrumb';
import Footer from '../container/Footer/Footer';
import ScrollToTop from '../components/ScrollToTop.jsx';
import ForexMain from '../container/Forex/ForexMain';
import ContactFromContainer from '../container/ContactFromContainer/ContactFromContainer';
import ContactInformationThree from '../container/ContactInformation/ContactInformationThree';


const Forex = () => {
    return (
        <React.Fragment>
            <SEO title="Hun Markets - Döviz Alım-Satımı" />
            <Header />
            <Breadcrumb 
                image="images/bg/1920x662-Forex.png"
                title="Döviz Alım-Satımı"
                content="Anasayfa"
                contentTwo="Döviz Alım-Satımı"
            />
            <ForexMain />
            <ContactInformationThree />
            {/* <AboutFive /> */}
            {/* <BrandContainer classOption="section-padding-bottom" /> */}
            {/* <Faq /> */}
            <Footer />
            <ScrollToTop />
        </React.Fragment>
    )
}

export default Forex;
import PropTypes from "prop-types";
import React, { useState } from 'react';
import { Link, useLocation } from "react-router-dom";
import CampainsList from "../../data/work/workDetails"
import Tilt from 'react-parallax-tilt';
import { useEffect } from "react";

const WorkDetailsContainer = () => {
    const [scale] = useState(0.95);
    let location = useLocation();
    let detailPageName = location.pathname.split("/")[2];
    const [myObj, setMyObj] = useState({
        id: 1,
        title: '',
        imageUrl: '',
        url: '',
        conditions: {
            1: '',
            2: '',
        }
    },)
    useEffect(() => {
        arrangeImage()
    }, [window.location.href])

    const arrangeImage = () => {
        CampainsList.forEach(element => {
            if (element.url === detailPageName) {
                setMyObj(element)
            }
        });
    }

    // console.log(myObj.imageUrl);
    return (
        <>
            {myObj &&
                <div className="section section-padding whyhunfx-section ">
                    <div className="row">
                        <div className="col-lg-5 offset-lg-1">
                            <div className="custom-column-thumbnail mt-lg-14 mt-1" data-aos="fade-up">
                                <Tilt scale={scale} transitionSpeed={4000}>
                                    <img className="whyhunfx" src={`${process.env.PUBLIC_URL + '/' + myObj.imageUrl}`} alt="" />
                                </Tilt>
                            </div>
                        </div>
                        <div className="col-lg-5">
                            <p className="col-lg-10 whyhunfx-p" data-aos="fade-up">
                                <ul className="promotion-details">
                                    <li>{myObj.conditions[1]}</li>
                                    <li>{myObj.conditions[2]}</li>
                                    <li>{myObj.conditions[3]}</li>
                                    <li>{myObj.conditions[4]}</li>
                                    <li>{myObj.conditions[5]}</li>
                                    <li>{myObj.conditions[6]}</li>
                                </ul>
                                <br />
                                <div style={{display:"flex", justifyContent:"center"}}>
                                <Link className="btn btn-primary btn-hover-secondary mt-xl-8 mt-lg-8 mt-md-6 mt-4 " to={process.env.PUBLIC_URL + "/campaigns"}>Yatırım Kampanyaları</Link>

                                </div>
                            </p>
                        </div>
                    </div>

                    {/* <div className="row mt-lg-20 mt-12">
                <div className="col-lg-4 col-md-12 col-12">
                    <div className="digital-marketing" data-aos="fade-up">
                        <h3 className="heading heading-h3">{data.subContent.title}</h3>
                    </div>
                </div>
                <div className="col-lg-7 col-md-12 col-12 offset-lg-1">
                    <div className="digital-marketing mt-lg-0 mt-6" data-aos="fade-up">
                        <div className="inner">
                            <p>{data.subContent.text}</p>
                        </div>
                    </div>
                </div>
            </div> */}

                    {/* <div className="custom-layout-gallery mt-lg-20 mt-12">
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-12">
                        <div className="thumbnail" data-aos="fade-up">
                            <img className="w-100" src={`${process.env.PUBLIC_URL}/${data.gallery.imageOne}`} alt="Agency" />
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-12 mt-lg-0 mt-md-0 mt-10">
                        <div className="thumbnail" data-aos="fade-up">
                            <img className="w-100" src={`${process.env.PUBLIC_URL}/${data.gallery.imageTwo}`} alt="Agency" />
                        </div>
                    </div>

                    <div className="col-lg-12 my-6">
                        <div className="thumbnail" data-aos="fade-up">
                            <img className="w-100" src={`${process.env.PUBLIC_URL}/${data.gallery.imageThree}`} alt="Agency" />
                        </div>
                    </div>
                </div>
            </div> */}


                </div>
            }
        </>
    )


}



WorkDetailsContainer.propTypes = {
    data: PropTypes.object
};

export default WorkDetailsContainer;

import {useState, useEffect, useRef} from "react";
import SectionTitleTwo from '../../components/SectionTitles/SectionTitleTwo';
import {Link} from "react-router-dom";
import Tilt from 'react-parallax-tilt';
import Parallax from 'parallax-js';

const AboutFive = () => {
    const [scale] = useState(1.04);
    const sceneEl = useRef(null);

    useEffect(() => {
        const parallaxInstance = new Parallax(sceneEl.current, {
        relativeInput: true,
        })
        
        parallaxInstance.enable();

        return () => parallaxInstance.disable();

    }, [])
    return (
        <div className="section section-padding-top section-padding-bottom-180">
            <div className="container">

                <div className="row">

                    <div className="col-xl-6 col-lg-6 col-12" data-aos="fade-up">
                        <div className="about-image-area">
                            <div className="about-image">
                                <Tilt scale={scale} transitionSpeed={4000}>
                                    <img src={process.env.PUBLIC_URL + "images/about/370x370-2.png"} alt="" />
                                </Tilt>
                            </div>
                            <div className="about-image">
                                <Tilt scale={scale} transitionSpeed={4000}>
                                    <img src={process.env.PUBLIC_URL + "images/about/370x400-1.png"} alt="" />
                                </Tilt>
                            </div>

                            <div className="shape shape-1" id="scene" ref={sceneEl}>
                                <span data-depth="1"><img src={process.env.PUBLIC_URL + "images/shape-animation/about-shape-1.png"} alt="" /></span>
                            </div>

                        </div>
                    </div>

                    <div className="col-xl-6 col-lg-6 col-12" data-aos="fade-up" data-aos-delay="300">
                        <div className="about-content-area">
                            <SectionTitleTwo 
                                subTitle="Hun Markets"
                                title="Biz Kimiz"
                            />

                            <p>Hun Markets, 2017 yılı itibariyle online trade alanında işlem yapmaya başlayan dünya çapında bir aracı kurumdur. Hun Markets denetimli bir broker olarak Genel Merkezi İngiltere’dir. Hun Markets olarak, yatırımcı fonlarının idaresi, güvenlik ve finansal raporlama konularında katı uyumluluk standartlarına tabi olarak faaliyet göstermekteyiz.
                                <br/>
                                <br/>
                                Kuruluşumuzdan bu yana, Hun Markets 170+ ülkede tüzel kişiler ve kurumsal yatırımcılara hizmet verecek şekilde başarılı bir şekilde genişledi ve hala büyüyoruz. Hun Markets olarak siz değerli yatırımcılarımıza kaliteli ve güvenilir hizmet vermek için Türkiye Cumhuriyeti piyasalarına giriş sağlamıştır. Aynı zamanda Hun Markets , hem tüzel kişiler hem de kurumsal yatırımcılara ticaret hizmetleri ve kolaylıklar sağlayan ödüllü bir global yatırım ve emtia komisyoncusudur.
                                <br/><br/>
                                Hun Markets yatırımcılarına mümkün olan en iyi ticaret koşullarını sağlama ve uzman danışmanları kullanan tüccarlara ve tüccarlara likiditesine sınırsız erişim sağlama politikası sayesinde, kendisini dünya çapındaki tüccarlar için tercih edilen global yatırım brokeri olarak konumlandırmıştır.
                                <br/><br/>
                                hunmarkets.com Hun Markets tarafına aittir ve onun tarafından işletilmektedir.
                            </p>

                            {/* <Link className="btn btn-primary btn-hover-secondary mt-xl-12 mt-lg-8 mt-md-6 mt-4" to={process.env.PUBLIC_URL + "/contact"}>İletişime Geçin</Link> */}
                        </div>
                    </div>

                </div>

            </div>
        </div>
    )
}

export default AboutFive;

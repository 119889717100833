import React from 'react';
import SEO from '../components/SEO';
import Header from "../partials/header/Header";
import Breadcrumb from '../container/Breadcrumb/Breadcrumb';
import WorkContainer from '../container/Work/WorkContainer';
import CallToAction from '../container/CallToAction/CallToAction';
import AboutFive from '../container/About/AboutFive';
import BrandContainer from '../container/Brand/BrandContainer';
import Faq from '../container/Faq/Faq';
import Footer from '../container/Footer/Footer';
import ScrollToTop from '../components/ScrollToTop.jsx';
import ContactInformationThree from '../container/ContactInformation/ContactInformationThree';


const Work = () => {
    return (
        <React.Fragment>
            <SEO title="Hun Markets - Promosyonlar" />
            <Header />
            <Breadcrumb 
                image="images/bg/1920x540-Promosyonlar.png"
                title="Promosyonlar"
                content="Anasayfa"
                contentTwo="Promosyonlar"
            />
            <WorkContainer />
            <ContactInformationThree />
            {/* <AboutFive /> */}
            {/* <BrandContainer classOption="section-padding-bottom" /> */}
            {/* <Faq /> */}
            <Footer />
            <ScrollToTop />
        </React.Fragment>
    )
}

export default Work;
import React, { useRef } from 'react';
import PropTypes from "prop-types"
import {Link} from "react-router-dom";


const Btn2 = (props) => {
    var location = 'https://wt.' + (window.location.hostname.replace('www.', ''))
    // const openWebTrader = () => {
    //     webtrader.current.style.display = 'block'
    // }
    const webtrader = useRef()

    return (
        <React.Fragment>
            {/* <div onMouseMove={openWebTrader}> */}
            <a href={location} style={{maxWidth: '6rem'}} className="btn btn-primary btn-hover-light btnLogIn mx-4">{props.name}</a>
            {/* </div> */}
        </React.Fragment>
    )
}

Btn2.propTypes = {
    name: PropTypes.string
}

export default Btn2
